/**
 * Extract login token from location hash.
 * @param {string} hash
 * @return {{ token: string | undefined, tokenType: string | undefined } }}}
 */
const matchToken = (hash) => {
  // The token typically be a JWT, so it will contain base64url characters and a "." separator.
  const match = /#\/(t|a|e|s)\/([a-zA-Z0-9-_.]+)/.exec(hash);
  if (!match) {
    return {
      tokenType: undefined,
      token: undefined,
    };
  }
  return {
    tokenType: match[1],
    token: match[2],
  };
};

export default matchToken;
