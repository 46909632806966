import isPlainObject from "lodash/isPlainObject";
import { createSelector } from "reselect";
import selectJson from "./selectJson";

const selectJsonObject = (selectJsonString) =>
  createSelector(selectJson(selectJsonString), (value) => {
    if (isPlainObject(value)) {
      return value;
    }
    return null;
  });

export default selectJsonObject;
