import React, { lazy } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { AnimatePresence } from "framer-motion";
import NotFound from "../components/NotFound";
import LoggedInRoute from "./LoggedInRoute";

const Welcome = lazy(() => import("../screens/Welcome"));
const Activities = lazy(() => import("../screens/Activities"));
const Form = lazy(() => import("../screens/Form"));
const ThankYou = lazy(() => import("../screens/ThankYou"));
const FormBuilder = lazy(() => import("../screens/FormBuilder"));
const Devices = lazy(() => import("../screens/Devices"));
const Submitted = lazy(() => import("../screens/Submitted"));
const Empty = lazy(() => import("../screens/Empty"));
const AbaLogin = lazy(() => import("../screens/AbaLogin"));

const getAnimationKey = (location) => location.pathname.split("/")[1];

const Routes = connect(() =>
  createStructuredSelector({
    location: (state) => state.router.location,
  })
)(({ location }) => {
  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <Switch location={location} key={getAnimationKey(location)}>
        <Route exact path="/" render={() => <Redirect to="/welcome/" />} />
        <LoggedInRoute path="/welcome/" exact component={Welcome} />
        <LoggedInRoute path="/activities/" exact component={Activities} />
        <LoggedInRoute
          path="/forms/:answersSheetId/:questionId?"
          component={Form}
        />
        <Route exact path="/auth/login" component={AbaLogin} />
        <Route path="/thank-you/:answersSheetId?" component={ThankYou} />
        <Route exact path="/form-builder" component={FormBuilder} />
        <Route exact path="/devices" component={Devices} />
        <Route exact path="/submitted" component={Submitted} />
        <Route exact path="/logged-out" component={Empty} />
        <Route exact path="/invalid-token" component={Empty} />
        <Route exact path="/questionnaires-completed" component={Empty} />
        <Route exact path="/error" component={Empty} />
        <Route exact path="/warning" component={Empty} />
        <Route component={NotFound} />
      </Switch>
    </AnimatePresence>
  );
});

const Router = ({ history }) => (
  <ConnectedRouter history={history}>
    <Routes />
  </ConnectedRouter>
);

Router.propTypes = {
  history: PropTypes.object.isRequired, //  eslint-disable-line react/forbid-prop-types
};

export default Router;
