import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ThemeProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const languageDirection = i18n.dir();

  useEffect(() => {
    document.documentElement.dir = languageDirection;
  }, [languageDirection]);

  return children;
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
