import { createSlice } from "@reduxjs/toolkit";
import { createGetAtKey } from "@zedoc/selectors";

const formSlice = createSlice({
  name: "form",
  initialState: {
    isQuestionReady: true,
    shouldGoThroughMissingQuestions: false,
    direction: "inline-end",
  },
  reducers: {
    setIsQuestionReady: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isQuestionReady = action.payload;
    },
    setShouldGoThroughMissingQuestions: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.shouldGoThroughMissingQuestions = action.payload;
    },
    setDirection: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.direction = action.payload;
    },
  },
});

export const {
  setIsQuestionReady,
  setShouldGoThroughMissingQuestions,
  setDirection,
} = formSlice.actions;

export const selectIsQuestionReady = createGetAtKey("form.isQuestionReady");
export const selectShouldGoThroughMissingQuestions = createGetAtKey(
  "form.shouldGoThroughMissingQuestions"
);
export const selectDirection = createGetAtKey("form.direction");

export default formSlice.reducer;
