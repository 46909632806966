import omit from "lodash/omit";
import forEach from "lodash/forEach";
import isPlainObject from "lodash/isPlainObject";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import { createSelector } from "reselect";
import { PURGE } from "redux-persist";
import { toSelector } from "@zedoc/selectors";
import gql from "graphql-tag";
import settings from "../utils/settings";
import { getUserLanguage } from "./preferences";

const { appName = "zedoc-patient-web", commitSha: appCommitSha } =
  settings.public;

export const ACTION_UPLOAD = "@STAGE/UPLOAD";
export const ACTION_DELETE = "@STAGE/DELETE";
export const ACTION_UPDATE = "@STAGE/UPDATE";
export const ACTION_DELETE_ALL = "@STAGE/DELETE_ALL";

export const STATE_INITIAL = "INITIAL";
export const STATE_DRAFT = "DRAFT";
export const STATE_ACTIVE = "ACTIVE";
export const STATE_ERROR = "ERROR";

export const isStageAction = (action) => {
  if (!isPlainObject(action)) {
    return false;
  }
  const { type } = action;
  return (
    type === ACTION_UPLOAD ||
    type === ACTION_DELETE ||
    type === ACTION_UPDATE ||
    type === ACTION_DELETE_ALL
  );
};

export const getStaged = (state) => state.stage;

export const isNotCompleted = (staged) => (answersSheet) => {
  const { id, state } = answersSheet;
  if (state === "COMPLETED") {
    return false;
  }
  return (
    !staged[id] ||
    staged[id].state === STATE_DRAFT ||
    staged[id].state === STATE_INITIAL
  );
};

export const isCompleted = (staged) => (answersSheet) => {
  if (!answersSheet) {
    return false;
  }
  const { id, state } = answersSheet;
  if (state === "COMPLETED") {
    return true;
  }
  return (
    staged[id] &&
    staged[id].state !== STATE_DRAFT &&
    staged[id].state !== STATE_INITIAL
  );
};

export const load = (selectId) =>
  createSelector(
    (state) => state && state.stage,
    toSelector(selectId),
    (stage, id) => id && stage && stage[id]
  );

export const createSelectTranslationId = (selectId) =>
  createSelector(
    load(selectId),
    (rawAnswersSheet) => rawAnswersSheet && rawAnswersSheet.translationId
  );

export const createSelectTranslationLanguage = (selectId) =>
  createSelector(
    load(selectId),
    (rawAnswersSheet) => rawAnswersSheet && rawAnswersSheet.language
  );

export const save = (
  id,
  {
    version = new Date().toISOString(),
    responses,
    variables,
    previousResponses,
    completionRate,
  }
) => ({
  type: ACTION_UPDATE,
  payload: omitBy(
    {
      version,
      responses,
      variables,
      previousResponses,
      completionRate,
      state: STATE_DRAFT,
    },
    isUndefined
  ),
  meta: {
    id,
  },
});

export const deleteDraft = (id) => ({
  type: ACTION_DELETE,
  meta: {
    id,
  },
});

export const deleteAllDrafts = () => ({
  type: ACTION_DELETE_ALL,
});

export const setTranslation = (id, translationId, language, languages) => ({
  type: ACTION_UPDATE,
  payload: {
    language,
    languages,
    translationId,
  },
  meta: {
    id,
  },
});

export const submit = (id, { responses, finalComputedResponses }) => ({
  type: ACTION_UPLOAD,
  payload: {
    responses,
    finalComputedResponses,
  },
  meta: {
    id,
  },
});

const getTimezone = () => {
  try {
    // https://stackoverflow.com/a/44935836/2817257
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) {
    return undefined;
  }
};

const ANSWERS_SHEET_FRAGMENT = gql`
  fragment AnswersSheetStateAndPipedResults on AnswersSheet {
    state
    pipedResults
  }
`;

export const createMiddleware =
  ({ client }) =>
  (store) => {
    const changeActiveToError = (error = "Upload was not completed") => {
      const staged = getStaged(store.getState());
      forEach(staged, ({ state }, id) => {
        if (state === STATE_ACTIVE) {
          // NOTE: If anything is active on initial load, it probably
          //       means that the upload was terminated for some reason.
          //       So we put item in "error" state in order to retry.
          store.dispatch({
            type: ACTION_UPDATE,
            payload: {
              state: STATE_ERROR,
              error,
            },
            meta: {
              id,
            },
          });
        }
      });
    };

    const scheduledRetries = {};
    const scheduleRetry = (answersSheetId) => {
      if (!scheduledRetries[answersSheetId]) {
        scheduledRetries[answersSheetId] = setTimeout(() => {
          store.dispatch({
            type: ACTION_UPLOAD,
            meta: {
              id: answersSheetId,
            },
          });
          delete scheduledRetries[answersSheetId];
        }, 5 * 1000);
      }
    };

    const uploadDraft = async (answersSheetId) => {
      const state = store.getState();
      const draft = load(answersSheetId)(state);
      if (!draft || draft.state !== STATE_DRAFT) {
        return Promise.resolve();
      }
      const {
        responses,
        variables,
        previousResponses,
        version,
        completionRate,
        translationId,
      } = draft;
      return Promise.resolve()
        .then(() =>
          client.mutate({
            mutation: gql`
              mutation PrepareUploadUrlForDraft(
                $input: ObtainPreSignedUrlInput
              ) {
                obtainPreSignedUrlForDraft(input: $input)
              }
            `,
            variables: {
              input: {
                answersSheetId,
              },
            },
          })
        )
        .then(({ data: { obtainPreSignedUrlForDraft } }) => {
          if (!obtainPreSignedUrlForDraft) {
            return undefined;
          }
          return fetch(obtainPreSignedUrlForDraft, {
            method: "PUT",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              encodedDraft: JSON.stringify({
                responses,
                variables,
                previousResponses,
                version,
                completionRate,
                translationId,
              }),
              encodedDraftOptions: {},
            }),
          }).then((response) => {
            if (response.status === 200) {
              return response.text();
            }
            return Promise.reject(new Error(response.statusText));
          });
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const scheduledJobs = {};
    const scheduleDraftUpload = (answersSheetId) => {
      if (!scheduledJobs[answersSheetId]) {
        scheduledJobs[answersSheetId] = setTimeout(() => {
          uploadDraft(answersSheetId);
          delete scheduledJobs[answersSheetId];
        }, 5 * 1000);
      }
    };

    /**
     * This will watch for changes in ApolloClient cache for the given answersSheetId.
     * When certain conditions are met, it will trigger either a draft upload or draft deletion.
     * For example, if answers sheet was completed on a different device, we will delete the local draft.
     * @param {string} id
     * @returns {{ unsubscribe: () => void }}
     */
    // See:
    // https://www.apollographql.com/docs/react/api/react/hooks-experimental#usefragment_experimental-api
    // https://github.com/apollographql/apollo-client/blob/9134aaf3b6fc398b2d82439b5b63848b533ae4c9/src/react/hooks/useFragment.ts
    const observeAnswersSheet = (id) => {
      const unsubscribe = client.cache.watch({
        id: `AnswersSheet:${id}`,
        query: client.cache.getFragmentDoc(
          ANSWERS_SHEET_FRAGMENT,
          "AnswersSheetStateAndPipedResults"
        ),
        callback: (data) => {
          if (!data.missing && data.result) {
            if (data.result.state === "COMPLETED") {
              store.dispatch(deleteDraft(id));
            } else {
              const state = store.getState();
              const staged = load(id)(state);
              if (staged && staged.state === STATE_ERROR) {
                // NOTE: Retry upload to the server, because it seems like the server does
                //       not consider this answers sheet completed yet.
                store.dispatch({
                  type: ACTION_UPLOAD,
                  meta: {
                    id,
                  },
                });
              }
            }
          }
        },
      });
      return {
        unsubscribe,
      };
    };

    const subscriptions = {};
    const updateApolloCacheSubscriptions = () => {
      const staged = getStaged(store.getState());
      forEach(staged, (_, id) => {
        if (!subscriptions[id]) {
          subscriptions[id] = observeAnswersSheet(id);
        }
      });
      forEach(subscriptions, (subscription, id) => {
        if (!staged[id]) {
          subscription.unsubscribe();
          delete subscriptions[id];
        }
      });
    };

    return (next) => (action) => {
      if (!isPlainObject(action)) {
        return next(action);
      }
      switch (action.type) {
        case "persist/REHYDRATE": {
          const result = next(action);
          changeActiveToError();
          updateApolloCacheSubscriptions();
          return result;
        }
        case ACTION_UPDATE: {
          const id = action.meta && action.meta.id;
          if (action.payload && action.payload.version) {
            scheduleDraftUpload(id);
          }
          if (action.payload && action.payload.state === STATE_ERROR) {
            scheduleRetry(id);
          }
          const result = next(action);
          updateApolloCacheSubscriptions();
          return result;
        }
        case ACTION_DELETE:
        case ACTION_DELETE_ALL: {
          const result = next(action);
          updateApolloCacheSubscriptions();
          return result;
        }
        case ACTION_UPLOAD: {
          const id = action.meta && action.meta.id;
          const state = store.getState();
          const uiLanguage = getUserLanguage(state);
          const draft = load(id)(state);
          if (!draft) {
            return Promise.resolve();
          }
          // const uiLanguages = ???;
          const payload = {
            ...draft,
            ...action.payload,
          };
          // NOTE: Let's not forget about pushing the action down the middleware chain,
          //       in order to properly update state.
          next({
            ...action,
            payload,
          });
          let encodedPipedResults;
          let encodedPipedResultsOptions;
          if (!isEmpty(payload.finalComputedResponses)) {
            encodedPipedResultsOptions = {};
            encodedPipedResults = JSON.stringify({
              finalComputedResponses: payload.finalComputedResponses,
            });
          }
          return Promise.resolve()
            .then(() =>
              client.mutate({
                mutation: gql`
                  mutation PrepareUploadUrl($input: ObtainPreSignedUrlInput) {
                    obtainPreSignedUrl(input: $input)
                  }
                `,
                variables: {
                  input: {
                    answersSheetId: id,
                    encodedPipedResultsOptions,
                    encodedPipedResults,
                  },
                },
              })
            )
            .then(({ data: { obtainPreSignedUrl } }) => {
              if (!obtainPreSignedUrl) {
                // NOTE: If server returned empty string, it means that it's not
                //       going to accept any uploads at all, i.e. we should
                //       discard and delete the draft. This behavior is leveraged
                //       in form builder preview backend when multiple people
                //       can attempt completing the same questionnaire.
                return undefined;
              }
              return fetch(obtainPreSignedUrl, {
                method: "PUT",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify({
                  encodedResultsOptions: {},
                  encodedResults: JSON.stringify({
                    responses: payload.responses,
                    variables: payload.variables,
                  }),
                  encodedPipedResultsOptions,
                  encodedPipedResults,
                  encodedMetadataOptions: {},
                  encodedMetadata: JSON.stringify({
                    userAgent: navigator.userAgent,
                    timezone: getTimezone(),
                    uiLanguage,
                    // uiLanguages,
                    language: payload.language,
                    languages: payload.languages,
                    translationId: payload.translationId,
                    appName,
                    appCommitSha,
                  }),
                }),
              }).then((response) => {
                if (response.status === 200) {
                  return response.text();
                }
                return Promise.reject(new Error(response.statusText));
              });
            })
            .then(() => {
              client.writeFragment({
                id: `AnswersSheet:${id}`,
                fragment: ANSWERS_SHEET_FRAGMENT,
                data: {
                  state: "COMPLETED",
                  pipedResults: {
                    finalComputedResponses: payload.finalComputedResponses,
                  },
                },
              });
            })
            .catch((err) => {
              store.dispatch({
                type: ACTION_UPDATE,
                payload: {
                  state: STATE_ERROR,
                  error: err.toString(),
                },
                meta: {
                  id,
                },
              });
              console.error(err);
            });
        }
        default:
          return next(action);
      }
    };
  };

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PURGE:
    case ACTION_DELETE_ALL:
      return initialState;
    default:
    // ...
  }
  const id = action.meta && action.meta.id;
  if (!id) {
    return state;
  }
  switch (action.type) {
    case ACTION_UPLOAD:
      return {
        ...state,
        [id]: {
          ...state[id],
          ...action.payload,
          error: null,
          state: STATE_ACTIVE,
        },
      };
    case ACTION_UPDATE: {
      return {
        ...state,
        [id]: {
          state: state.state || STATE_INITIAL,
          ...state[id],
          ...action.payload,
        },
      };
    }
    case ACTION_DELETE: {
      if (!state[id]) {
        return state;
      }
      return omit(state, id);
    }
    default:
      return state;
  }
};
