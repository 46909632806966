/**
 * @template {object} T
 */
class ObjectBuilder {
  /**
   * @param {Partial<T>} [properties]
   */
  constructor(properties = {}) {
    this.properties = { ...properties };
  }

  /**
   * @template {string & keyof T} K
   * @template {T[K]} PropType
   * @param {(v: unknown) => v is PropType} isOfType
   * @param {K} name
   * @param {unknown} value
   * @returns {this}
   */
  setSafely(isOfType, name, value) {
    if (isOfType(value)) {
      // eslint-disable-next-line no-param-reassign
      this.properties[name] = value;
    }
    return this;
  }

  /**
   * @template {string & keyof T} K
   * @template {T[K]} PropType
   * @param {K} name
   * @param {PropType} value
   * @returns {this}
   */
  set(name, value) {
    this.properties[name] = value;
    return this;
  }

  /**
   * @returns {Partial<T>}
   */
  get() {
    return this.properties;
  }
}

export default ObjectBuilder;
