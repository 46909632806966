export const CLOCK_ACTION = "@CLOCK/UPDATE";

export const selectClock = (state) => state.clock;
export const selectClockAsDate = (state) =>
  new Date(state && state.clock && state.clock.ts);
export const selectClockAsTimestamp = (state) => state.clock && state.clock.ts;

const ONE_MINUTE = 60 * 1000;
const getCurrentMinute = (ts = new Date().getTime()) =>
  Math.floor(ts / ONE_MINUTE);

export const clockMiddleware = (store) => {
  setInterval(() => {
    const state = store.getState();
    const prevMinute = getCurrentMinute(state && state.clock && state.clock.ts);
    const nextMinute = getCurrentMinute();
    if (prevMinute !== nextMinute) {
      store.dispatch({
        type: CLOCK_ACTION,
        payload: {
          ts: new Date(nextMinute * ONE_MINUTE).getTime(),
        },
      });
    }
  }, 1000);
  return (next) => next;
};

export const clockReducer = (
  state = {
    ts: new Date(getCurrentMinute() * ONE_MINUTE).getTime(),
  },
  action
) => {
  if (action.type === CLOCK_ACTION) {
    return {
      ...state,
      ts: action.payload.ts,
    };
  }
  return state;
};
