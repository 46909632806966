import React from "react";
import PropTypes from "prop-types";
import Notification from "../components/Notification";

export default function NotificationsManager({ setNotify }) {
  const [notifications, setNotifications] = React.useState([]);

  const createNotification = ({ type, message, duration }) => {
    setNotifications((prevNotifications) => {
      return [
        ...prevNotifications,
        {
          message,
          type,
          duration,
          id: prevNotifications.length,
        },
      ];
    });
  };

  React.useEffect(() => {
    setNotify(({ type, message, duration }) =>
      createNotification({
        type,
        message,
        duration,
      })
    );
  }, [setNotify]);

  const handleOnCloseNotification = (id) => {
    const filteredNotifications = notifications.filter(
      (_, index) => id !== index,
      []
    );

    setNotifications(filteredNotifications);
  };

  return notifications.map(({ id, type, message, duration }, index) => (
    <Notification
      key={id}
      onClose={() => handleOnCloseNotification(index)}
      type={type}
      message={message}
      duration={duration}
    />
  ));
}

NotificationsManager.propTypes = {
  setNotify: PropTypes.func.isRequired,
};
