import map from "lodash/map";
import filter from "lodash/filter";
import keyBy from "lodash/keyBy";
import uniqBy from "lodash/uniqBy";

/**
 * @typedef {object} Response
 * @property {string} questionId
 * @property {{ value: unknown }} answer
 */

/**
 * @typedef {object} AnswersSheet
 * @property {string} id
 * @property {?string} encodedPipedResults
 * @property {?object} encodedPipedResultsOptions
 * @property {string} activityId
 * @property {?{ finalComputedResponses: Response[] }} pipedResults
 */

/**
 * @typedef {object} Activity
 * @property {string} id
 * @property {string} participationId
 * @property {boolean} isConsent
 */

/**
 * @param {Activity[]} activities
 * @param {AnswersSheet[]} answersSheets
 * @returns {Promise<string[]>} list of rejected participation ids
 */

/**
 * @param {Activity[]} activities
 * @param {AnswersSheet[]} answersSheets
 * @param {?object[]} staged
 * @returns {string[]} list of rejected participation ids
 */

const getRejectedParticipations = (answersSheets, activities, staged) => {
  const participationsBlock = uniqBy(
    filter(
      map(answersSheets, (answersSheet) => {
        const pipedResults =
          answersSheet.pipedResults || staged[answersSheet.id];

        const finalComputedResponses =
          pipedResults && pipedResults.finalComputedResponses;

        const activityByAnswerSheet =
          activities &&
          activities.find(
            (activity) => activity.id === answersSheet.activityId
          );
        if (activityByAnswerSheet && activityByAnswerSheet.isConsent) {
          let isAnswerSheetsConsentResponse = true;
          if (finalComputedResponses && finalComputedResponses.length) {
            const responseConsentById = keyBy(
              finalComputedResponses,
              "questionId"
            );
            const responseConsent = responseConsentById["final:consent"];
            if (responseConsent && !responseConsent.answer.value) {
              isAnswerSheetsConsentResponse = false;
            }
          }
          return isAnswerSheetsConsentResponse
            ? null
            : activityByAnswerSheet.participationId;
        }

        return null;
      }),
      (participationId) => participationId
    )
  );
  return participationsBlock;
};

export default getRejectedParticipations;
