import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getFontSize, getTheme } from "../store/preferences";

const ThemeProvider = ({ children }) => {
  const globalStateTheme = useSelector(getTheme);
  const fontSize = useSelector(getFontSize);

  useEffect(() => {
    if (
      globalStateTheme === "dark" ||
      (!globalStateTheme &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [globalStateTheme]);

  useEffect(() => {
    document.documentElement.style.fontSize = `${(fontSize * 100) / 16}%`;
  }, [fontSize]);

  return children;
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
