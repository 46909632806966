import { createSlice } from "@reduxjs/toolkit";
import { createGetAtKey } from "@zedoc/selectors";

const initialState = {
  userLanguage: null,
  fontSize: 16,
  animationSpeed: 400,
  theme: null,
  isLargeFontSizeDisabled: false,
  // FIXME: Move to another location
  dexcomAccessToken: null,
  dexcomAccessTokenExpiresAt: null,
  dexcomRefreshToken: null,
  originalFormURL: null,
};

const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    setUserLanguage: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.userLanguage = action.payload;
    },
    setFontSize: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.fontSize = action.payload;
    },
    setAnimationSpeed: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.animationSpeed = action.payload;
    },
    setTheme: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.theme = action.payload;
    },
    setIsLargeFontSizeDisabled: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isLargeFontSizeDisabled = action.payload;
    },
    setDexcomAccessToken: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.dexcomAccessToken = action.payload;
    },
    setDexcomAccessTokenExpiresAt: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.dexcomAccessTokenExpiresAt = action.payload;
    },
    setDexcomRefreshToken: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.dexcomRefreshToken = action.payload;
    },
    setOriginalFormURL: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.originalFormURL = action.payload;
    },
  },
});

export const {
  setUserLanguage,
  setFontSize,
  setAnimationSpeed,
  setTheme,
  setIsLargeFontSizeDisabled,
  setDexcomAccessToken,
  setDexcomAccessTokenExpiresAt,
  setDexcomRefreshToken,
  setOriginalFormURL,
} = preferencesSlice.actions;

export const getUserLanguage = createGetAtKey("preferences.userLanguage");
export const getFontSize = createGetAtKey("preferences.fontSize");
export const getAnimationSpeed = createGetAtKey("preferences.animationSpeed");
export const getTheme = createGetAtKey("preferences.theme");
export const getIsLargeFontSizeDisabled = createGetAtKey(
  "preferences.isLargeFontSizeDisabled"
);
export const getDexcomAccessToken = createGetAtKey(
  "preferences.dexcomAccessToken"
);
export const getDexcomAccessTokenExpiresAt = createGetAtKey(
  "preferences.dexcomAccessTokenExpiresAt"
);
export const getDexcomRefreshToken = createGetAtKey(
  "preferences.dexcomRefreshToken"
);
export const getOriginalFormURL = createGetAtKey("preferences.originalFormURL");

export default preferencesSlice.reducer;
